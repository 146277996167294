<template>
  <div>
    <div>
      <div class="small-12 medium-12 columns tight">
        <table v-if="ccprofiles.length && ready" id="cards">
          <tr v-for="ccp in ccprofiles" :key="ccp.DataKey">
            <td class="cardtype">
              {{ ccp.ResCardType === 'V' ? 'VISA' : 'MasterCard' }}
            </td>
            <td class="l4">{{ ccp.ResDataMaskedPan }}</td>
            <td class="exp">
              {{ ccp.ResDataExpDate.slice(0, 2) }}/{{
                ccp.ResDataExpDate.slice(2)
              }}
            </td>
            <td class="default">
              <input
                type="radio"
                :checked="
                  order
                    ? order.paymentProfile.DataKey === ccp.DataKey
                    : selectedCC && selectedCC.DataKey === ccp.DataKey
                "
                @change="setCCDefault(ccp)"
                class="input radio"
                name="defaultcc"
              />
            </td>
            <td v-if="showControls" class="action">
              <!-- <button class="button tiny " @click.prevent="editProfile(ccp)">Edit</button> -->
              <button class="button tiny " @click.prevent="updateProfile(ccp)">
                Update Card Info
              </button>
              <button class="button tiny " @click.prevent="deleteProfile(ccp)">
                {{ $t('common.delete') }}
              </button>
            </td>
          </tr>
        </table>
      </div>

      <!-- <div class="small-12 medium-4 columns" style="margin-bottom:0.5rem;"> -->
      <!-- </div> -->
      <button
        v-if="showAdd"
        class="button small"
        @click.prevent="addCard = !addCard"
      >
        {{ $t('client.myAccount.vault.addCard') }}
      </button>

      <div class="addacard" v-if="showAdd && addCard && ready">
        <h4 class="section__title" style="clear:both;">
          {{ $t('client.myAccount.vault.addCard') }}
        </h4>
        <div class="callout warning" v-if="tokenErr" v-html="tokenErr"></div>
        <tokenizer
          :user="user"
          :profile="selectedCC"
          :frameborder="frameborder"
          :framewidth="framewidth"
          :frameheight="frameheight"
          :env="appconfig.siteConfig.env"
          :host="appconfig.siteConfig.checkout.gatewayHost"
          :url="appconfig.siteConfig.checkout.gatewayTokenUrl"
          :profile-id="appconfig.siteConfig.checkout.profileId"
          :css-textbox-pan="css_textbox_pan"
          :enable-exp="enable_exp"
          :css-textbox-exp="css_textbox_exp"
          :enable-cvd="enable_cvd"
          :css-textbox-cvd="css_textbox_cvd"
          :display-labels="display_labels"
          :css-input-label="css_input_label"
          :css-label-pan="css_label_pan"
          :css-label-exp="css_label_exp"
          :css-label-cvd="css_label_cvd"
          :pan-label="pan_label"
          :exp-label="exp_label"
          :cvd-label="cvd_label"
          @tokenizerSubmit="submitted = true"
        ></tokenizer>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash/fp'
import { mapGetters } from 'vuex'
import tokenizer from './MonerisTokenizer.vue'

export default {
  created: function() {
    /**
     * NB: if NOT using location.host test, comment out below for local dev, restore for production
     */
    if (!~window.location.host.indexOf('localhost')) {
      this.$http.get('/users/ccprofile').then(res => {
        // console.info(' ::: creditprofiles create, fetched', res)
        this.k = res.data.ref
        if (!res.data.data.length) {
          this.addCard = true
          return
        }
        this.ccprofiles = _.map(
          i => i.response,
          _.remove(i => i.error, res.data.data)
        )
        this.selectedCC = _.find(
          i =>
            i.default || i.DataKey === this.user.profile.extradata.mtidDefault,
          this.ccprofiles
        ) // this.user.profile.extradata.mtidDefault
        // ? this.ccprofiles.find(p => p.DataKey === this.user.profile.extradata.mtidDefault)
        // : this.ccprofiles.data;
        // this.k = res.data.ref;
        // if (!this.ccprofiles.length) this.addCard = true
        this.callback('start', this.selectedCC)
      })
    } else {
      this.k.host = this.appconfig.siteConfig.checkout.gatewayTokenUrl
      /**
       * NB: and comment out below for production, restore for local dev
       ***********/
      const res = [
        {
          success: true,
          data: [
            {
              response: {
                ResDataEmail: 'gh@y.ca',
                ResDataAvsStreetName: 'A Street',
                ResDataAvsStreetNumber: '123',
                ResDataExpDate: '1708',
                ResDataMaskedPan: '4242***4242',
                ResDataCryptType: '7',
                ResDataAvsZipcode: 'y7y7y7',
                ResDataCustId: 'VsHUVW2vqUfCWOCS',
                ResDataPhone: '5675675678',
                ResCardType: null,
                ResSuccess: 'true',
                DataKey: 'DFJwMVLmjcxnfWczu6EyhjDY2'
              }
            }
          ]
        }
      ]
      this.ccprofiles = _.map(i => i.response, res[0].data)
      this.selectedCC = this.user.profile.extradata.mtidDefault
        ? this.ccprofiles.find(
            p => p.DataKey === this.user.profile.extradata.mtidDefault
          )
        : this.ccprofiles[0]

      if (!this.selectedCC) {
        if (this.ccprofiles.length) this.selectedCC = this.ccprofiles[0]
        // else this.addCard = true
      }

      this.callback('start', this.selectedCC)
    }
    /**
     * END
     ***********/
  },
  mounted() {
    /**
     * NB: if NOT using location.host test, comment out below for local dev, restore for production
     */
    if (!~window.location.host.indexOf('localhost')) {
      window.addEventListener('message', this.respMsg, false)
    }
    if (
      this.appconfig &&
      this.appconfig.siteConfig &&
      this.appconfig.siteConfig.checkout
    ) {
      this.ready = true
    }
    // if (!this.ccprofiles.length && this.ready) {
    //   this.addCard = true
    // }
    // console.info(' :::::::: app configs?', this.config)
  },
  beforeDestroy: function() {
    window.removeEventListener('message', this.respMsg)
  },
  components: { tokenizer },
  props: {
    user: Object,
    billingAddress: Object,
    order: { type: [Object, Boolean], default: false },
    showControls: { type: Boolean, default: false },
    showAdd: { type: Boolean, default: true },
    callback: Function,
    // appconfig.siteConfig.checkout.gatewayHost: String,
    tokenProfile: String
  },
  data() {
    return {
      ccprofiles: [],
      selectedCC: null,
      addCard: false,
      k: {},
      tokenErr: '',
      submitted: false,
      ready: false,

      frameborder: '0',
      framewidth: '300',
      frameheight: '200',
      css_textbox_pan: 'width:12rem;margin-bottom:0.75rem;padding:0.5rem;', // Optional - CSS applied to the pan text box specifically.
      enable_exp: 1, // Optional - Must be set to 1 for expiry date text box to be displayed
      css_textbox_exp: 'width:6rem;margin:0 1rem 0.75rem 0;padding:0.5rem;', // Optional - CSS applied to the expiry date text box specifically.
      enable_cvd: 1, // Optional - Must be set to 1 for CVD text box to be displayed
      css_textbox_cvd: 'width:6rem;margin:0 1rem 0.75rem 0;padding:0.5rem;', // Optional - CSS applied to the CVD text box specifically.
      display_labels: 1, // Optional â€“ 0 for no labels, 1 for traditional labels, 2 for place holder labels.
      css_input_label:
        'display:block;font-family:Helvetica,Arial,sans-serif;margin-bottom:0.3rem;', // Optional â€“ CSS for input labels
      css_label_pan: 'width:12rem;font-size:0.75rem;padding:0;margin:0;', // Optional â€“ CSS for card number label
      css_label_exp:
        'width:6rem;font-size:0.75rem;padding:0;margin:0 1rem 0 0;', // Optional â€“ CSS for expiry date label
      css_label_cvd:
        'width:6rem;font-size:0.75rem;padding:0;margin:0 1rem 0 0;', // Optional â€“ CSS for CVD label
      pan_label: null,
      exp_label: null,
      cvd_label: null,
      logdata: { ifrurl: '' }
    }
  },
  watch: {
    appconfig: function(n) {
      console.info(' ::: appconfig changed', n)
      if (n && n.siteConfig && n.siteConfig.checkout) this.ready = true
    }
  },
  computed: {
    ...mapGetters({
      appconfig: 'const'
    })
  },
  methods: {
    cardType(pan) {
      if (!pan) return ''
      return pan[0] === '4' ? 'Visa' : 'Mastercard'
    },
    respMsg: function(e) {
      console.info(' :::: credit profiles heard respMsg', e, this.ready)
      const origin = e.origin || e.originalEvent.origin
      // For Chrome, the origin property is in the event.originalEvent object.
      if (
        !this.ready ||
        origin !== 'https://' + this.appconfig.siteConfig.checkout.gatewayHost
      ) {
        return
      }
      var respData = JSON.parse(e.data) // NB: respData.dataKey is a nonce
      if (!respData.dataKey) {
        if (!this.submitted) return // if event source is window it is an 'onload' fire, not a submit
        this.showError(respData.responseCode)
        this.$http.post('/users/ccprofileerror', {
          source: window.location.href,
          origin: origin,
          host: this.appconfig.siteConfig.checkout.gatewayHost,
          url: this.logdata.ifrurl,
          profileid: this.appconfig.siteConfig.checkout.profileId,
          useragent: window.navigator.userAgent,
          response: respData
        })
        return
      }
      this.$http
        .post('/users/ccprofile', {
          user: this.user._id,
          address: this.billingAddress,
          token: respData.dataKey
        })
        // addCreditProfile(this.user._id, this.billingAddress, respData.dataKey)
        .then(async res => {
          if (!res.data.Complete) {
            this.tokenErr = res.data.Message
            return
          }
          this.addCard = false
          await this.callback('new', res.data)
          this.$http
            .get('/users/ccprofile')
            .then(res => {
              this.ccprofiles = _.map(i => i.response, res.data.data)
              this.selectedCC =
                this.ccprofiles.length === 1
                  ? this.ccprofiles[0]
                  : _.find(
                      i =>
                        i.default ||
                        i.DataKey === this.user.profile.extradata.mtidDefault,
                      this.ccprofiles
                    ) // this.user.profile.extradata.mtidDefault
              // ? this.ccprofiles.find(p => p.DataKey === this.user.profile.extradata.mtidDefault)
              // : this.ccprofiles[0];
              this.k = res.data.ref
              if (!this.ccprofiles.length) this.addCard = true
              this.submitted = false
              this.callback('start', this.selectedCC)
            })
            .catch(err => {
              this.callback('new', { error: err, res: res.data })
            })
        })
        .catch(err => {
          console.log('error creating credit profile', err)
        })
    },
    showError(code) {
      this.tokenErr =
        'Sorry, there was an issue saving the card: <span>' +
        code
          .map(errcode =>
            this.$t('system.error.moneris.token[' + errcode + ']')
          )
          .join(', ') +
        '</span>'
    },
    selectCC: function(id) {
      this.selectedCC = this.ccprofiles.find(p => p.DataKey === id)
    },
    setCCDefault: function(profile) {
      this.callback('select', profile)
      // const _profile = _.assign({}, this.user.profile);
      // _profile.extradata.mtidDefault = id; //this.ccprofiles.find(p => p.DataKey === id);
      // this.profileUpdate(this.user, _profile);
    },
    updateProfile: function(id) {
      const ctx = this
      this.$vex.dialog.open({
        className: 'vex-theme-os update-card-dialog',
        message: 'Update Card Info',
        input: `<div>
                    <iframe id="moneris_vault" style="height:30rem; min-width: 37.5rem; width: 100%;"></iframe>
                    <div id="closer" style="height: 10rem; padding: 1.5rem; display: none;"><h3><i class="fa fa-check" aria-hidden="true"></i> Your card has been updated</h3></div>
                    <input type="hidden" id="complete" name="complete" value="false">
                  </div>`,
        buttons: [
          window.$.extend({}, this.$vex.dialog.buttons.YES, {
            text: 'Cancel',
            className: 'pending vex-dialog-button-primary'
          })
        ],
        afterOpen: function() {
          if (~window.location.href.indexOf('localhost')) return
          console.info(
            ' update thing',
            window.$(this.contentEl).find('#moneris_vault')[0].contentDocument
          )
          window.$(this.contentEl).find('#moneris_vault')[0].contentDocument
            .write(`
              <html><head></head><body>
              <div id="monerisResponse"></div>
              <FORM id="hppdp" METHOD="POST" ACTION="https://${ctx.appconfig.siteConfig.checkout.gatewayHost}/HPPDP/index.php" target="_self" >
                  <INPUT TYPE="HIDDEN" NAME="res_id" VALUE="${ctx.k.a}">
                  <INPUT TYPE="HIDDEN" NAME="res_key" VALUE="${ctx.k.b}">
                  <INPUT TYPE="HIDDEN" NAME="data_key" VALUE="${id.DataKey}">
                  <INPUT TYPE="HIDDEN" NAME="cc_crypt_type" VALUE="7">
                  <!--MORE OPTIONAL VARIABLES CAN BE DEFINED HERE -->
                  <INPUT TYPE="SUBMIT" NAME="SUBMIT" VALUE="Click to proceed to Secure Page">
              </FORM>
              </body></html>
            `)
          // try will fail if content is xdomain, so when we get home, remove iframe and announce success.
          const _el = window.$(this.contentEl).find('#moneris_vault')[0]
          const _up = window.$(_el).parent()[0]
          const _sub = window.$(_up).find('#closer')[0]
          const _complete = window.$(this.contentEl).find('#complete')[0]
          const _done = window
            .$(this.contentEl)
            .find('.vex-dialog-button-primary')[0]
          window.$(_el).on('load', function() {
            try {
              // this.contentWindow.location.href
              window
                .$(_done)
                .removeClass('pending')
                .text('Done')
              _el.remove()
              // window.$(_el).off('load');
              window.$(_sub).css('display', 'block')
              window.$(_complete).attr('value', 'true')
            } catch (err) {
              // console.log('______err',err);
            }
          })
        },
        callback: function(val) {
          // do something, pass to callback...
          if (_.isEmpty(val) || val.complete === 'false') return
          ctx.callback('update', 'done')
          this.$http.get('/users/ccprofile').then(res => {
            ctx.ccprofiles = _.map(i => i.response, res.data.data)
            ctx.selectedCC = _.find(i => i.default, ctx.ccprofiles) // ctx.user.profile.extradata.mtidDefault
            // ? ctx.ccprofiles.find(p => p.DataKey === ctx.user.profile.extradata.mtidDefault)
            // : ctx.ccprofiles[0];
            ctx.k = res.data.ref
            if (!ctx.ccprofiles.length) ctx.addCard = true
            ctx.callback('start', ctx.selectedCC.DataKey)
          })
        }
      })
    },
    deleteProfile: function(ccprofile) {
      this.$vex.dialog.confirm({
        message: 'Are you sure you want to delete this card?',
        callback: value => {
          if (value) {
            this.$http
              .post(`/users/ccprofile/remove/${ccprofile.DataKey}`, {
                user: ccprofile.ResDataCustId
              })
              // removeCreditProfile(ccprofile.DataKey, ccprofile.ResDataCustId)
              .then(res => {
                if (res.data.success) {
                  this.ccprofiles = _.without(
                    p => p.DataKey === res.data.data.DataKey,
                    this.ccprofiles
                  )
                  // if (res.data.success) {
                  this.callback('delete', res.data)
                  this.$http.get('/users/ccprofile').then(res => {
                    this.ccprofiles = _.map(i => i.response, res.data.data)
                    this.k = res.data.ref
                    if (!this.ccprofiles.length) {
                      this.addCard = true
                      return
                    }
                    this.callback('start', this.selectedCC.DataKey)
                    this.selectedCC = _.find(i => i.default, this.ccprofiles) // this.user.profile.extradata.mtidDefault
                    // ? this.ccprofiles.find(p => p.DataKey === this.user.profile.extradata.mtidDefault)
                    // : this.ccprofiles[0];
                  })
                  // }
                  // const _profile = _.assign({}, this.user.profile);
                  // _profile.extradata.mtids = _.map(p => p.DataKey, this.ccprofiles); //_.without(ccprofile.DataKey, _profile.extradata.mtids);
                  // if (_profile.extradata.mtidDefault === ccprofile.DataKey) _profile.extradata.mtidDefault = '';
                  // this.profileUpdate(this.user, _profile);
                } else if (res.data.error) {
                  // handle moneris error
                } else {
                  // profile is in use on active orders...
                  this.callback('delete', res[0])
                }
              })
          }
        }
      })
    }
  },
  events: {
    'moneris.submit'(url) {
      this.logdata.ifrurl = url
    }
  }
}
</script>

<style>
.addacard h4 {
  padding-top: 1rem;
}
.update-card-dialog {
  padding-top: 60px !important;
}
.vex-dialog-button-primary.pending {
  background-color: rgb(223, 47, 16) !important;
}
.floater {
  display: none;
}
.floater.Jeffers,
.floater.Lawrence,
.floater.Hoyland,
.floater.SCROSATI,
.floater.Parkinson {
  display: block;
}
</style>
